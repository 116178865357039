import React from 'react';

interface Props {
  mainText: string;
  placeName?: string;
  image?: string;
  className?: string;
}

const FrontBanner: React.FC<Props> = ({ mainText, placeName, image, className }) => {
  const bannerClassName = className ?? '';

  return (
    <div className={`banner-container ${bannerClassName}`}>
      {image && <img src={image} alt="banner-image"></img>}
      <div className="banner-text-container">
        <span className="banner-main-text dia-bold">{mainText}</span>
        {placeName && <span className="dia-normal banner-subtitle">{placeName}</span>}
      </div>
    </div>
  );
};

export default FrontBanner;
