import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Header, Input, RichTextRenderer } from 'components';
import DropDown, { OptionListsType } from 'components/DropDown';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { checkOutActions } from 'redux/reducers/checkOut';
import { selectCheckOutExperience, selectCheckOutLoading } from 'redux/selectors/checkOut';
import { walletIcon, locationIcon } from 'assets/icons';
import moment from 'moment';
import { ExperienceTimeSlotType, RecurringTypeEnum } from 'models/experiences';
import getSymbolFromCurrency from 'currency-symbol-map';
import { filterUpcomingSlots } from 'utils/timeSlots';
import checkOutApi from 'api/checkout';

export interface ExcitesYouValuesModel {
  email: string;
  fullName: string;
  timeSlot: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Required'),
  fullName: yup.string().required('Required'),
  timeSlot: yup.string().required('Required'),
});

const Checkout: React.FC = () => {
  const [dateOptions, setDateOptions] = useState<OptionListsType[]>([]);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const experience = useAppSelector(selectCheckOutExperience);
  const experienceLoading = useAppSelector(selectCheckOutLoading);

  const isMultiDay = experience?.recurringType === RecurringTypeEnum.MULTI_DAY;

  const upcomingSlots = useMemo(
    () =>
      experience
        ? filterUpcomingSlots(
            experience.timeSlots,
            experience.place?.city?.timeZone
          )
        : [],
    [experience]
  );

  const currencySymbol = !!experience?.place?.city?.country?.currency
    ? getSymbolFromCurrency(
        experience?.place.city.country.currency.toUpperCase()
      )
    : '$';

  const pricing = useMemo(() => experience?.pricing[0], [experience]);
  const isFree = useMemo(
    () => experience?.pricing && experience.pricing.length === 0,
    [experience]
  );

  const isSoldOut = useMemo(
    () => upcomingSlots?.every((s) => s.bookingsAmount === experience?.capacityLimit),
    [experience]
  );

  useEffect(() => {
    if (!id) {
      navigate('/event/error');
    } else {
      dispatch(checkOutActions.getExperienceById({ id }));
    }

    return () => {
      dispatch(checkOutActions.reset());
    };
  }, [id]);

  useEffect(() => {
    if (!experience) return;
    setDatesOptions();
  }, [experience]);

  const setDatesOptions = () => {
    if (!experience) return;

      let options = [] as OptionListsType[];
      const sortedSlots = [...upcomingSlots].sort(
        (a, b) =>
          new Date(a.startDateTime).getTime() -
          new Date(b.endDateTime).getTime()
      );
      sortedSlots.forEach((time, i) => {
        const isSoldOut = time.bookingsAmount === experience?.capacityLimit;
        options.push({
          key: time._id,
          value: isMultiDay ? formatMultiSlot(time) : formatStandardSlot(time),
          disabled: isSoldOut,
        });
      });

      setDateOptions(options);
  };

  const formatStandardSlot = (timeSlot: ExperienceTimeSlotType) => {
    return moment(timeSlot.startDateTime).format('D MMMM:') +
    ` ${moment.utc(timeSlot.startDateTime).format('HH:mm')}-${moment
      .utc(timeSlot.endDateTime)
      .format('HH:mm')}`
  };

  const formatMultiSlot = (timeSlot: ExperienceTimeSlotType) => {
    return `${moment(timeSlot.startDateTime).format('D MMMM')} - ${moment(timeSlot.endDateTime).format('D MMMM')}`;
  };

  const submitHandler = async (
    values: ExcitesYouValuesModel
  ): Promise<void> => {
    if (!id || experienceLoading || checkoutLoading) return;

    try {
      setCheckoutLoading(true);
      const { url } = await checkOutApi.confirmCheckOut({
        _id: id,
        timeSlotId: values.timeSlot,
        fullName: values.fullName,
        email: values.email,
      });

      if (isFree) {
        navigate('/event/success');
      }

      if (!url) return;
      window.location.href = url;
    } catch (error: any) {
      dispatch(
        checkOutActions.setEventError(error?.response?.data?.message || '')
      );
      navigate('/event/error');
    } finally {
      setCheckoutLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      timeSlot: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const btnTitle = !dateOptions?.length ? 'NO TIME SLOTS LEFT' : isSoldOut ? 'SOLD OUT' : 'CONFIRM';

  if (!experience) return null;

  return (
    <>
      <Header withBackArrow={false} />
      <div className="page check-out-page">
        <div className="container">
          <div className="event-container">
            <img
              className="event-image"
              src={experience.imageUrl}
              alt="banner-image"
            />
            <div className="details-wrap">
              <span className="beaufortPro-lg title">{experience.title}</span>
              <div className="pricing-wrap">
                <img src={walletIcon} alt="wallet-icon" />
                {pricing && pricing?.amount > 0 ? (
                  <div className="pricing">
                    <span className="dia-normal currency">
                      {currencySymbol}
                    </span>
                    <span className="dia-md price">
                      {pricing?.amount / 100}
                    </span>
                  </div>
                ) : (
                  <span className="dia-md price">Free event</span>
                )}
              </div>
              {experience.place.address && (
                <div className="location-wrap">
                  <img src={locationIcon} alt="location-icon" />
                  <span className="dia-md location">
                    {experience.place.address || ''} 
                    {experience.addressDetails ? `(${experience.addressDetails})` : ''}
                  </span>
                </div>
              )}
              <RichTextRenderer className="dia-normal desc" html={experience.description} />
            </div>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {!isSoldOut && dateOptions?.length > 0 && (
                <>
                  <Input
                    id={'email'}
                    name={'email'}
                    type={'text'}
                    label={'Email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched.email && Boolean(formik.errors.email)
                    }
                    errorText={formik.errors.email}
                  />
                  <Input
                    id={'fullName'}
                    name={'fullName'}
                    type={'text'}
                    label={'Full name'}
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    errorText={formik.errors.fullName}
                  />
                </>
              )}
              {dateOptions?.length > 0 && !isSoldOut && (
                <DropDown
                  id={'timeSlot'}
                  name={'timeSlot'}
                  type={'single'}
                  label={'DATE AND TIME'}
                  value={
                    dateOptions.find((op) => op.key === formik.values.timeSlot)
                      ?.value || ''
                  }
                  options={dateOptions}
                  onChange={(option) => {
                    formik.setFieldValue('timeSlot', option.key);
                  }}
                  disabledTitle={'SOLD OUT'}
                  isError={
                    formik.touched.timeSlot && Boolean(formik.errors.timeSlot)
                  }
                  errorText={formik.errors.timeSlot}
                />
              )}
              <Button
                disabled={isSoldOut || !dateOptions?.length}
                type={'submit'}
                className="submit-btn"
                text={checkoutLoading ? 'Loading...' : btnTitle}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
