import { FrontBanner, Header } from 'components';

const Success = (): JSX.Element => {
  return (
    <>
      <Header withBackArrow={false} />
      <div className="page">
        <div className="container">
          <FrontBanner
            mainText={'Congratulation!'}
            placeName={'You successfully booked the event'}
          />
        </div>
      </div>
    </>
  );
};

export default Success;
