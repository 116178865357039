import React from 'react';
import ReactGA from 'react-ga4';
import MainRouter from 'routes';
import HistoryRouter from 'routes/HistoryRouter';
import history from 'utils/route-history';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_PROJECT_ID as string);

const App = () => {
  return (
    <HistoryRouter history={history}>
      <MainRouter />
    </HistoryRouter>
  );
};

export default App;
