import { backArrowIcon, logoIcon, logoWhiteIcon } from 'assets/icons';

interface Props {
  withBackArrow: boolean;
  onBack?: () => void;
  className?: string;
  hasLogo?: boolean;
  title?: string;
  isWhiteLogo?: boolean;
}

const Header: React.FC<Props> = ({ withBackArrow, onBack, className, hasLogo = true, isWhiteLogo, title }) => {
  const headerClassName = className ?? '';
  return (
    <div className={`header ${withBackArrow ? 'header-back-btn' : ''} ${headerClassName}`}>
      {withBackArrow && (
        <span onClick={onBack} className="step-arrow-back">
          <img src={backArrowIcon} alt="Back" />
        </span>
      )}
      {hasLogo && <img src={isWhiteLogo ? logoWhiteIcon : logoIcon} alt="Logo"></img>}
      {title && <span className="dia-bold header-title">{title}</span>}
      {withBackArrow && <div></div>}
    </div>
  );
};

export default Header;
