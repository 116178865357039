import { ExperienceTimeSlotType } from 'models/experiences';
import moment from 'moment-timezone';

export const filterUpcomingSlots = (timeSlots: ExperienceTimeSlotType[], timeZone: string): ExperienceTimeSlotType[] => {
  if (!timeSlots?.length || !timeZone) return [];
  const now = moment.tz(moment(), timeZone);

  return timeSlots.filter((slot) => {
    return moment(slot.endDateTime)
      .utc(false)
      .isSameOrAfter(moment(now).utc(true));
  });
};
