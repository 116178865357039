import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExperienceDto } from 'models/experiences';
import { GetExperienceByIdRequest, GetExperienceByIdResponse } from 'types/experiences';

export interface CheckOutState {
  loading: boolean;
  error: any;
  experience: ExperienceDto | null;
};

const initialState: CheckOutState = {
  loading: false,
  error: null,
  experience: null,
};

const checkOutSlice = createSlice({
  name: 'checkOut',
  initialState: initialState,
  reducers: {
    getExperienceById(state: CheckOutState, action: PayloadAction<GetExperienceByIdRequest>) {
      state.loading = true;
    },
    getExperienceByIdSuccess( state: CheckOutState, action: PayloadAction<GetExperienceByIdResponse>) {
      state.loading = false;
      state.experience = action.payload;
    },
    getExperienceByIdFailed(state: CheckOutState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    setEventError(state: CheckOutState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return {
        loading: false,
        experience: null,
        error: state.error,
      };
    },
  },
});

// Actions
export const checkOutActions = checkOutSlice.actions;

// Reducer
const checkOutReducer = checkOutSlice.reducer;
export default checkOutReducer;
