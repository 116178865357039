import { Place } from './place';

export enum AccessTypeEnum {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum AccessibilityTypeEnum {
  IN_PERSON = 'in_person',
  HYBRID = 'hybrid',
  VIRTUAL = 'virtual',
  TRIP = 'trip',
}

export enum RecurringTypeEnum {
  SINGLE = 'standard',
  MULTI_DAY = 'multi_day',
}

export type DaySlotsType = StandardDaySlot[] | MultiDaySlot;

export type TimeSlotType = {
  _id?: string;
  startTime: string;
  endTime: string;
  bookingsAmount?: number;
};

export type StandardDaySlot = {
  date: Date;
  timeSlots: TimeSlotType[];
};

export type MultiDaySlot = {
  _id?: string;
  startDate: Date;
  endDate: Date;
};

export type ExperiencePricing = {
  amount: number;
  currency: string;
  priceId: string;
  type: PricingTypeEnum;
};

export enum PricingTypeEnum {
  STANDARD = 'standard',
}

export type ExperienceTimeSlotType = {
  _id: string;
  startDateTime: Date;
  endDateTime: Date;
  bookingsAmount: number;
};

export type ExperienceDto = {
  _id: string;
  title: string;
  capacityLimit: number;
  guestsPerMember: number;
  isSingle: boolean;
  place: Place;
  recurringType: RecurringTypeEnum;
  timeSlots: ExperienceTimeSlotType[];
  pricing: ExperiencePricing[];
  addressDetails: string;
  imageUrl: string;
  description: string;
};
