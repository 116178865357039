import { removeIcon, selectIcon } from 'assets/icons';
import React, { useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import numberToWords from 'number-to-words';

export type OptionListsType = {
  key: string;
  value: string;
  disabled?: boolean;
};

interface Props {
  id: string;
  name: string;
  type: 'single' | 'multiple';
  max?: number;
  options: OptionListsType[];
  value: string | string[];
  label: string;
  isError?: boolean;
  errorText?: string;
  onChange: (value: OptionListsType) => void;
  onRemove?: (value: OptionListsType) => void;
  humanizeMax?: boolean;
  closeOnOutsideClick?: boolean;
  children?: any;
  closeWhenMaxReached?: boolean;
  disabledTitle?: string;
}

const DropDown: React.FC<Props> = ({
  options,
  value,
  max,
  type,
  label,
  isError,
  errorText,
  onChange,
  humanizeMax,
  closeOnOutsideClick = true,
  children,
  closeWhenMaxReached,
  disabledTitle,
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  useOnclickOutside(
    () => {
      if (closeOnOutsideClick) {
        setOpenMenu(false);
      }
    },
    { refs: [selectRef] }
  );

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (closeWhenMaxReached && max && value?.length === max) {
      setOpenMenu(false);
    }
  }, [max, value, closeWhenMaxReached]);

  return (
    <div>
      <div
        ref={selectRef}
        tabIndex={1}
        className={`select-container ${isError ? 'error' : ''}`}
      >
        <select style={{ display: 'none' }} />
        <span className={`input-label ${isError ? 'error' : ''}`}>{label}</span>
        <div>
          <div
            className="select-options-container"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <span className="select-options-text">
              {type === 'single'
                ? value
                  ? value
                  : 'Select'
                : max && max > 0
                ? `Select up to ${
                    humanizeMax ? numberToWords.toWords(max) : max
                  }`
                : 'Select'}
            </span>
            <img
              className={`select-icon ${openMenu ? 'open' : ''}`}
              src={selectIcon}
              alt="select"
            />
          </div>
          {openMenu && (
            <div className="select-options-item-container">
              {options.map((item) => (
                <div
                  key={item.key}
                  className={`select-options-item ${item?.disabled ? 'disabled' : ''}`}
                  onClick={() => {
                    if (item.disabled) return;
                    if (max && Array.isArray(value) && max === value.length) {
                      return;
                    }
                    onChange(item);
                    if (type === 'single') {
                      setOpenMenu(false);
                    }
                  }}
                >
                  {item.value}{' '}{disabledTitle && item.disabled && <span className={`dia-thin select-item-disabled-text`}>({disabledTitle})</span>}
                </div>
              ))}
            </div>
          )}
        </div>
        {!openMenu && children}
      </div>
      {isError && <span className="input-label-error">{errorText}</span>}
    </div>
  );
};

export default DropDown;
