import { ConfirmCheckoutRequest, GetExperienceByIdRequest, GetExperienceByIdResponse } from 'types/experiences';
import axiosApi from './config';

const checkInApi = {
  confirmCheckOut(data: ConfirmCheckoutRequest): Promise<any> {
    const url = 'checkout/experiences/book';
    return axiosApi.post(url, data);
  },
  getExperienceById(data: GetExperienceByIdRequest): Promise<GetExperienceByIdResponse> {
    const url = `checkout/experiences/${data.id}`;
    return axiosApi.get(url);
  },
};

export default checkInApi;
