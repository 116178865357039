import { PayloadAction } from '@reduxjs/toolkit';
import checkOutApi from 'api/checkout';
import axios from 'axios';
import history from 'utils/route-history';
import { call, put, takeLatest } from 'redux-saga/effects';
import { checkOutActions } from 'redux/reducers/checkOut';
import { GetExperienceByIdResponse } from 'types/experiences';

function* getExperienceById(data: PayloadAction<any>) {
  try {
    const response: GetExperienceByIdResponse = yield call(checkOutApi.getExperienceById, data.payload);
    yield put(checkOutActions.getExperienceByIdSuccess(response));
  } catch (error) {
    console.log(`Failed to get experience`, error);
    history.push('/event/error');
    if (axios.isAxiosError(error)) {
      yield put(checkOutActions.getExperienceByIdFailed(error.response?.data.message));
    }
  }
}

export default function* checkOutSaga() {
  yield takeLatest(checkOutActions.getExperienceById.type, getExperienceById);
}
