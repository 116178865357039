import { useAppSelector } from 'app/hooks';
import { FrontBanner, Header } from 'components';
import { selectCheckOutError } from 'redux/selectors/checkOut';

const ErrorPage = (): JSX.Element => {
  const error = useAppSelector(selectCheckOutError);
  console.log(error, '__error')
  return (
    <>
      <Header withBackArrow={false} />
      <div className="page">
        <div className="not-found-container container">
          <FrontBanner
            mainText={'Something went wrong'}
            placeName={error || 'Can\'t get the event information'}
          />
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
