import { FC } from 'react';
import parse from 'html-react-parser';

type Props = {
  html: string;
  className?: string;
};

const RichTextRenderer: FC<Props> = ({ html, className }) => {
  const cleanedHTML = html.replace(/ style="[^"]*"/g, '');
  const plainText = parse(cleanedHTML);

  const customClassName = className ?? '';

  return (
    <div className={`reach-text-renderer ${customClassName}`}>
      {plainText || ''}
    </div>
  );
};

export default RichTextRenderer;
