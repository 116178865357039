import React from 'react';

interface Props {
  type: 'button' | 'submit';
  text: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ text, type, onClick, className, disabled }) => {
  const customClassName = className ?? '';
  return (
    <div className="button-container">
      <button disabled={disabled} className={`button ${customClassName} ${disabled ? 'disabled' : ''}`} type={type} onClick={onClick}>
        <span className="button-text dia-bold">{text}</span>
      </button>
    </div>
  );
};

export default Button;
