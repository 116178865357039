import { Route, Routes } from 'react-router-dom';
import Checkout from 'pages/Checkout';
import ErrorPage from 'pages/ErrorPage';
import Success from 'pages/Success';

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/event/:id" element={<Checkout />} />
      <Route path="/event/error" element={<ErrorPage />} />
      <Route path="/event/success" element={<Success />} />
    
      <Route path='*' element={<ErrorPage />}/>
    </Routes>
  );
};

export default MainRouter;
